 :root {
     --color-green1: #1F622F;
     --color-green2: #2E9F46;
     --color-green3: #4FBB4A;
     --color-blue1: #152B5F;
     --color-blue2: #186D9C;
     --color-yellow1: #BDD750;

     /* --color-bg1: #F5F5F5; */
     --color-bg1: #dee2e6;
     --color-bg2: #F4F5F7;
     --color-bg3: #BDD750;
     --color-red: #EC1D24;
     --color-text-1: #212529;
     --color-nav-admin: #BDD750;

 }

 .btn {
     align-self: center;
     width: 180px;
     height: 30px;
     font-size: 14px;
     font-weight: 500;
     border-radius: 5px;
     color: white;
     border: 1px solid white;
 }

.attach {
     background-color: var(--color-green2);
 }

.headings {
    background-color: #DEE2E6;
    font-weight: bold;
}

.rows:hover {
    cursor: pointer;
    background-color: var(--color-bg1);
}


.enroll {
    margin-left: 10px;
    height: 30px;
    width: 30px;
    border-radius: 5px;
    background-color: var(--color-green2);
    color: #ffffff;
    border: 1px solid white;
}

.enroll:hover {
    cursor: pointer;
    background-color: #ffffff;
    color: var(--color-green2);
    border: var(--color-green2) 1px solid;
}

.attach:hover {
    background-color: #ffffff;
    color: var(--color-green2);
    border: var(--color-green2) 1px solid;
}