:root {
    --color-green1: #1F622F;
    --color-green2: #2E9F46;
    --color-green3: #4FBB4A;
    --color-blue1: #152B5F;
    --color-blue2: #186D9C;
    --color-yellow1: #BDD750;

    /* --color-bg1: #F5F5F5; */
    --color-bg1: #dee2e6;
    --color-bg2: #F4F5F7;
    --color-bg3: #BDD750;
    --color-bg4: #f8f9fa;
    --color-red: #EC1D24;
}

.pan {

    margin: 20px 20%;
    padding: 0 2%;
    background-color: var(--color-bg4);
    min-height: 92vh;

}

ul.b {
    list-style-type: square !important;
}