/* Country Visits */

.countryvisits {
  display: grid;
  grid-template-columns: 30% 70%;
  margin: 40px 0px;
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
}

.rcchart1 {
  padding-left: 20px;
}

.rcchart2 {
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  margin: 40px 0px;
}

.engagements {
  margin: 40px 0px;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
}
