 :root {
     --color-green1: #1F622F;
     --color-green2: #2E9F46;
     --color-ship: #149c50;
     --color-bg-white: #F8F9FA;
     --color-red: #d90429;
     --color-edit: #fca311;
     --color-list: #003566;
     --color-bg2: #F4F5F7;
 }

.card {
    background-color: var(--color-bg-white);
    border-radius: 5px;
  
}

.img{
    max-width: 100%;
    object-fit: contain;
}

.label {
    font-weight: 500;
    color: #000000;
    margin-right: 10px;
}

.btn {
    align-self: center;
    width: 180px;
    height: 30px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px;
    color: white;
    border: 1px solid white;
}

.btnsm {
    align-self: center;
    margin-left: 1rem;
    width: 30px;
    height: 30px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px;
    color: white;
    border: 1px solid white;
}

.btn:hover {
    cursor: pointer;
}

.quote {
    background-color: var(--color-green2);
}

.edit {
    background-color: var(--color-edit);
}

.del {
    background-color: var(--color-red);
}
.attach {
    background-color: var(--color-green2);
}

.list {
    background-color: var(--color-list);
}
.ship {
    background-color: var(--color-ship);
}


.quote:hover {
    background-color: #ffffff;
    color: var(--color-green2);
    border: var(--color-green2) 1px solid;
}

.edit:hover {
    background-color: #ffffff;
    color: var(--color-edit);
    border: var(--color-edit) 1px solid;
}

.del:hover {
    background-color: #ffffff;
    color: var(--color-red);
    border: var(--color-red) 1px solid;
}
.attach:hover {
    background-color: #ffffff;
    color: var(--color-green2);
    border: var(--color-green2) 1px solid;
}
.list:hover {
    background-color: #ffffff;
    color: var(--color-list);
    border: var(--color-list) 1px solid;
}

.ship:hover {
    background-color: #ffffff;
    color: var(--color-ship);
    border: var(--color-ship) 1px solid;
}



.pid {
    padding-top: 2px;
    padding-bottom: 2px;
    margin: auto 2px;
    width: auto;
    display: inline-block;
}


.unverified {
    color: #d90429;
}

.verified {
    color: var(--color-green2);
}

.title{
    display: flex;
    justify-content: space-between;
}

.favicon {
    border-radius: 4px;
    border: none;
    height: 30px;
    width: 30px;
    margin-left: 10px;
}

.favgrey {
    color: #8d99ae;
}

.favgreen {
background-color: var(--color-green2);
color: white;
}

.favgold {
color: #ff8000;
}

 .paragraph {
     white-space: pre-wrap;
 }

 /* RESPONSIVE ... media queries........................................................................................ */
 @media (max-width: 600px) {

     .label,
     .value {
         font-size: 1rem !important;
     }

 }