:root {
    --color-green1: #1F622F;
    --color-green2: #2E9F46;
    --color-green3: #4FBB4A;
    --color-blue1: #152B5F;
    --color-blue2: #186D9C;
    --color-yellow1: #BDD750;

    /* --color-bg1: #F5F5F5; */
    --color-bg1: #dee2e6;
    --color-bg2: #F4F5F7;
    --color-bg3: #BDD750;
    --color-bg4: #f8f9fa;
    --color-red: #EC1D24;
}

.pan {

    margin: 20px auto;
    padding: 0 20%;
    background-color: var(--color-bg4);

}

.btnsubmit {
   
    background-color: var(--color-green3);
    font-size: 16px;
    font-weight: 600;
    color: white;

}

.btncancel{

    font-size: 16px;
    font-weight: 600;
    color: white;

}

.btnsubmit:hover {
    background-color: var(--color-green2);
    color: white;
}


.card {
  font-size: 1,5rem;
  padding: 2rem;
  border-radius: 5px;
  margin-bottom: 0,5rem;
  background-color: #ffffff;
} 

.thumbnail {
    max-width: 150px;
    position: relative;
}

.thumb {
    position: relative;
}


.delimg {
    border-radius: 4px;
    border: none;
    position: absolute;
    background: #FFFFFF;
    color: #EC1D24;
    top: 10px;
    right: 20px;
    height: 30px;
    width: 30px;
}


/* RESPONSIVE ... media queries........................................................................................ */

@media (max-width: 600px) {

    .card {
        font-size: 0, 8rem;
        padding: 10px 5px;
    }

    .pan {
        padding: 0 10%;
    }

    h4 {
        font-size: 1rem !important;
    }

    span,
    a {
        font-size: 12px !important;
    }

}